const courseSettings = {
  language: "en",
  name: "Data Analysis with Python 2021",
  siteUrl: "https://data-analysis-with-python-21.mooc.fi",
  githubUrl: "https://github.com/rage/data-analysis-with-python-material",
  subtitle: "Get started with analyzing data",
  slug: "data-analysis-with-python-2021",
  organizationName: "MOOC",
  tmcCourse: "data-analysis-with-python-2021",
  quizzesId: "f63429fb-28b8-4ee5-bb15-1df4154e2e4b",
  tmcOrganization: "mooc",
  bannerPath: "banner.svg",
  showExerciseDescriptionWhenNotLoggedIn: true,
  courseVariants: [],
}

module.exports = courseSettings
